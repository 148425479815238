<template>
  <div class="main-wrapper block-space">
    <div class="container">
      <h1 class="h3 m-0 w-100 text-default text-center">
        {{ $t('Views.FrontDirectory.title') }}
      </h1>
      <p class="text-center">{{ $t('Views.FrontDirectory.desc') }}</p>
      <v-row v-if="loading">
        <div
          class="col-12 col-sm-6 col-md-4 col-lg-3"
          v-for="(item, index) in 4"
          :key="index"
        >
          <v-sheet
            :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
            class="pa-3"
          >
            <v-skeleton-loader
              class="mx-auto"
              max-width="300"
              type="card"
            ></v-skeleton-loader>
          </v-sheet>
        </div>
      </v-row>

      <v-row v-else class="mx-3 mx-sm-0">
        <div
          class="col-12 col-sm-6 col-md-4 col-lg-3 px-2"
          v-for="(item, index) in data"
          :key="index"
        >
          <div class="shadow-lg card p-3 h-100">
            <router-link class="h-100" :to="'/asociations/' + item.link">
              <v-img
                v-if="item.logo"
                :src="item.logo.route"
                alt="image"
                class="logo-img mx-auto"
              />
              <div class="d-flex align-items-center">
                <h1 class="h6">{{ item.name }}</h1>
              </div>
            </router-link>
          </div>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
  import Pagination from 'Components/FrontendComponents/Pagination'
  export default {
    data() {
      return {
        data: [],
        loading: null,
      }
    },
    // Vuetify components provide
    // a theme variable that is
    // used to determine dark
    inject: {
      theme: {
        default: { isDark: false },
      },
    },
    created() {
      this.getCompanies()
    },
    components: {
      Pagination: Pagination,
    },
    methods: {
      async getCompanies() {
        this.loading = true

        axios.get('companies-list?limit=30&active=1&type=1').then((response) => {
          this.data = response.data
          this.loading = false
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .logo-img {
    width: 100%;
    max-width: 300px;
  }

  .shadow-lg {
    transform: scale(1);
    transition: transform 0.5s ease-out;
  }
  .shadow-lg:hover {
    transform: scale(1.05);
    transition: transform 0.5s ease-out;
  }
</style>
